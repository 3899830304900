// src/pages/Feed.js
import React, { useContext, useState } from 'react';
import axios from 'axios';
import { BillsContext } from '../context/BillsContext';
import Bill from '../components/Bill';
import BillDetailsModal from '../components/BillDetailsModal';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ButtonGroup, Button, Spinner } from 'react-bootstrap';

const Feed = () => {
  const { bills, loading, categories } = useContext(BillsContext);
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [selectedBill, setSelectedBill] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };

  const handleBillClick = async (billId) => {
    try {
      const response = await axios.get(`https://api.whatsthe411.co.uk/api/bills/${billId}`);
      setSelectedBill(response.data);
      setShowModal(true);
    } catch (error) {
      console.error('Error fetching bill details', error);
    }
  };

  if (loading && bills[selectedCategory]?.length === 0) {
    return <Spinner animation="border" />;
  }

  return (
    <div className="feed">
      <h1>Parliament Bills</h1>
      <ButtonGroup className="mb-3">
        {categories.map(category => (
          <Button
            key={category}
            variant={category === selectedCategory ? 'primary' : 'secondary'}
            onClick={() => handleCategoryChange(category)}
          >
            {category}
          </Button>
        ))}
      </ButtonGroup>
      <div className="bill-feed">
        {bills[selectedCategory]?.map(bill => (
          <Bill key={bill.billId} bill={bill} onClick={() => handleBillClick(bill.billId)} />
        ))}
      </div>
      {selectedBill && (
        <BillDetailsModal
          show={showModal}
          onHide={() => setShowModal(false)}
          bill={selectedBill}
        />
      )}
    </div>
  );
};

export default Feed;
