// src/components/Bill.js
import React from 'react';
import Card from 'react-bootstrap/Card';
import 'bootstrap/dist/css/bootstrap.min.css';

const Bill = ({ bill, onClick }) => {
  const { shortTitle, currentHouse, originatingHouse, lastUpdate, introducedSessionId } = bill;

  return (
    <Card className="mb-3" onClick={onClick}>
      <Card.Body>
        <Card.Title>{shortTitle}</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">Introduced Session ID: {introducedSessionId}</Card.Subtitle>
        <Card.Text>
          <strong>Current House:</strong> {currentHouse} <br />
          <strong>Originating House:</strong> {originatingHouse} <br />
          <strong>Last Update:</strong> {new Date(lastUpdate).toLocaleDateString()}
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default Bill;
