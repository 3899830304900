// src/App.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Feed from './pages/Feed';
import Members from './pages/Members';
import MemberProfile from './pages/MemberProfile';
import Government from './pages/Government';
import NavBar from './components/NavBar';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BillsProvider } from './context/BillsContext';
import { MembersProvider } from './context/MembersContext';
import { GovernmentProvider } from './context/GovernmentContext';
import Debate from './pages/Debate';

function App() {
  return (
    <Router>
      <BillsProvider>
        <MembersProvider>
          <GovernmentProvider>
            <div className="App">
              <NavBar />
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/bills" element={<Feed />} />
                <Route path="/members" element={<Members />} />
                <Route path="/members/:memberId" element={<MemberProfile />} />
                <Route path="/government" element={<Government />} />
                <Route path="/debate/:debateId" element={<Debate />} />
              </Routes>
            </div>
          </GovernmentProvider>
        </MembersProvider>
      </BillsProvider>
    </Router>
  );
}

export default App;
