import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

export const MembersContext = createContext();

export const MembersProvider = ({ children }) => {
  const [members, setMembers] = useState({ Commons: [], Lords: [] });
  const [contributions, setContributions] = useState({});

  useEffect(() => {
    const fetchInitialMembers = async () => {
      try {
        const [commonsResponse, lordsResponse] = await Promise.all([
          axios.get('https://members-api.parliament.uk/api/Members/Search', {
            params: { House: 1, skip: 0, take: 20 }
          }),
          axios.get('https://members-api.parliament.uk/api/Members/Search', {
            params: { House: 2, skip: 0, take: 20 }
          })
        ]);

        setMembers({
          Commons: commonsResponse.data.items,
          Lords: lordsResponse.data.items
        });
      } catch (error) {
        console.error('Error fetching initial members', error);
      }
    };

    fetchInitialMembers();
  }, []);

  const fetchMembers = async (house, skip = 0, take = 20) => {
    try {
      const response = await axios.get('https://members-api.parliament.uk/api/Members/Search', {
        params: { House: house === 'Commons' ? 1 : 2, skip, take }
      });
      setMembers(prev => ({
        ...prev,
        [house]: skip === 0 ? response.data.items : [...prev[house], ...response.data.items]
      }));
    } catch (error) {
      console.error('Error fetching members', error);
    }
  };

  const fetchMemberContributions = async (memberId) => {
    if (!contributions[memberId]) {
      try {
        const response = await axios.get(`https://members-api.parliament.uk/api/Members/${memberId}/ContributionSummary`);
        setContributions(prev => ({ ...prev, [memberId]: response.data.items }));
      } catch (error) {
        console.error('Error fetching member contributions', error);
      }
    }
  };

  return (
    <MembersContext.Provider value={{ members, contributions, fetchMembers, fetchMemberContributions }}>
      {children}
    </MembersContext.Provider>
  );
};
