// src/components/BillDetailsModal.js
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';

const BillDetailsModal = ({ show, onHide, bill }) => {
  const { shortTitle, longTitle, summary, sponsors, currentStage, isDefeated, billWithdrawn, isAct } = bill;

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{shortTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>{longTitle}</h4>
        {summary && <p>{summary}</p>}
        <p><strong>Current Stage:</strong> {currentStage.description} ({currentStage.abbreviation})</p>
        <p><strong>Is Defeated:</strong> {isDefeated ? 'Yes' : 'No'}</p>
        <p><strong>Is Withdrawn:</strong> {billWithdrawn ? 'Yes' : 'No'}</p>
        <p><strong>Is Act:</strong> {isAct ? 'Yes' : 'No'}</p>
        <h5>Sponsors</h5>
        {sponsors.map((sponsor, index) => (
          <div key={index}>
            <p>{sponsor.member.name}</p>
            <p>{sponsor.member.party}</p>
            <p>{sponsor.member.memberFrom}</p>
            <img src={sponsor.member.memberPhoto} alt={sponsor.member.name} />
          </div>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default BillDetailsModal;
