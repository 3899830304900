// src/pages/MemberProfile.js
import React, { useContext, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { MembersContext } from '../context/MembersContext';
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/pages/MemberProfile.css';

const MemberProfile = () => {
  const { memberId } = useParams();
  const { members, contributions, fetchMemberContributions, loading } = useContext(MembersContext);

  useEffect(() => {
    console.log(`Fetching contributions for member ID: ${memberId}`);
    fetchMemberContributions(memberId);
  }, [memberId, fetchMemberContributions]);

  if (loading) {
    console.log('Loading members data...');
    return <Spinner animation="border" />;
  }

  const member = [...members.Commons, ...members.Lords].find(m => m.value.id === parseInt(memberId));
  if (!member) {
    console.error(`Member with ID ${memberId} not found`);
    return <div>Member not found</div>;
  }

  const memberContributions = contributions[memberId] || [];
  console.log(`Rendering profile for member: ${member.value.nameDisplayAs}`);
  console.log(`Contributions for member ${memberId}:`, memberContributions);

  return (
    <div className="container">
      <Card className="mb-3">
        <Card.Img variant="top" src={member.value.thumbnailUrl} />
        <Card.Body>
          <Card.Title>{member.value.nameDisplayAs}</Card.Title>
          <Card.Text>
            <strong>Party:</strong> {member.value.latestParty.name} <br />
            <strong>House:</strong> {member.value.latestHouseMembership.house === 1 ? 'Commons' : 'Lords'} <br />
            <strong>Constituency:</strong> {member.value.latestHouseMembership.membershipFrom} <br />
          </Card.Text>
        </Card.Body>
      </Card>
      <h2 className="feed-title">Contributions</h2>
      {memberContributions.length === 0 ? (
        <p>No contributions found.</p>
      ) : (
        memberContributions.map(contribution => (
          <div key={contribution.value.debateId} className="feed-item">
            <div className="feed-item-title">{contribution.value.debateTitle}</div>
            <div className="feed-item-date">
              <strong>Date:</strong> {new Date(contribution.value.sittingDate).toLocaleDateString()}
            </div>
            <div className="feed-item-text">
              <strong>Section:</strong> {contribution.value.section} <br />
              <strong>House:</strong> {contribution.value.house} <br />
              <strong>Total Contributions:</strong> {contribution.value.totalContributions} <br />
              <strong>Speeches:</strong> {contribution.value.speechCount} <br />
              <strong>Questions:</strong> {contribution.value.questionCount} <br />
              <strong>Supplementary Questions:</strong> {contribution.value.supplementaryQuestionCount} <br />
              <strong>Interventions:</strong> {contribution.value.interventionCount} <br />
              <Link
                to={`/debate/${contribution.value.debateWebsiteId}`}
                className="view-debate-link"
              >
                View Debate
              </Link>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default MemberProfile;
