import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

export const GovernmentContext = createContext();

export const GovernmentProvider = ({ children }) => {
  const [governmentPosts, setGovernmentPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchGovernmentPosts = async () => {
      try {
        const response = await axios.get('https://api.whatsthe411.co.uk/api/government-posts');
        setGovernmentPosts(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching government posts', error);
        setLoading(false);
      }
    };

    fetchGovernmentPosts();
  }, []);

  return (
    <GovernmentContext.Provider value={{ governmentPosts, loading }}>
      {children}
    </GovernmentContext.Provider>
  );
};
