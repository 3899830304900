import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/pages/Debate.css';

const Debate = () => {
  const { debateId } = useParams();
  const [debate, setDebate] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDebate = async () => {
      try {
        const response = await fetch(`https://api.whatsthe411.co.uk/api/debate/${debateId}`);
        const data = await response.json();
        setDebate(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching debate data:', error);
        setLoading(false);
      }
    };

    fetchDebate();
  }, [debateId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!debate) {
    return <div>Debate not found.</div>;
  }

  return (
    <div className="container debate-page">
      <div className="debate-overview">
        <h1>{debate.Overview.Title}</h1>
        <p><strong>Date:</strong> {new Date(debate.Overview.Date).toLocaleDateString()}</p>
        <p><strong>Location:</strong> {debate.Overview.Location}</p>
        <p><strong>House:</strong> {debate.Overview.House}</p>
      </div>
      <div className="debate-content">
        {debate.Items.map(item => (
          <div key={item.ItemId} className={`debate-item ${item.ItemType.toLowerCase()}`}>
            {item.ItemType === 'Timestamp' && (
              <div className="timestamp">
                <p><strong>Time:</strong> {item.Value}</p>
              </div>
            )}
            {item.ItemType === 'Contribution' && (
              <div className="contribution">
                <p><strong>{item.AttributedTo}</strong></p>
                <p>{item.Value}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Debate;
