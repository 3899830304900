// src/pages/Home.js
import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button } from 'react-bootstrap';

const Home = () => {
  return (
    <div className="container text-center">
      <h1>Welcome to What's the 411?</h1>
      <p>Choose a section to explore:</p>
      <Link to="/bills">
        <Button variant="primary" className="m-2">Bills</Button>
      </Link>
      <Link to="/members">
        <Button variant="primary" className="m-2">Members</Button>
      </Link>
      <Link to="/government">
        <Button variant="primary" className="m-2">Government</Button>
      </Link>
    </div>
  );
};

export default Home;
