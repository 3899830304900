import React, { useContext, useState, useEffect } from 'react';
import { MembersContext } from '../context/MembersContext';
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';
import { ButtonGroup, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

const Members = () => {
  const { members, fetchMembers } = useContext(MembersContext);
  const [selectedCategory, setSelectedCategory] = useState('Commons');
  const [page, setPage] = useState(0);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  useEffect(() => {
    fetchMembers(selectedCategory, 0);
  }, [selectedCategory, fetchMembers]);

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    setPage(0);
    fetchMembers(category, 0);
  };

  const loadMoreMembers = async () => {
    setIsLoadingMore(true);
    await fetchMembers(selectedCategory, (page + 1) * 20);
    setPage(page + 1);
    setIsLoadingMore(false);
  };

  if (!members[selectedCategory].length) {
    return <Spinner animation="border" />;
  }

  return (
    <div className="container">
      <h1>Members of Parliament</h1>
      <ButtonGroup className="mb-3">
        <Button
          variant={selectedCategory === 'Commons' ? 'primary' : 'secondary'}
          onClick={() => handleCategoryChange('Commons')}
        >
          Commons
        </Button>
        <Button
          variant={selectedCategory === 'Lords' ? 'primary' : 'secondary'}
          onClick={() => handleCategoryChange('Lords')}
        >
          Lords
        </Button>
      </ButtonGroup>
      <div className="row">
        {members[selectedCategory].map(member => (
          <div className="col-md-4" key={member.value.id}>
            <Card className="mb-3">
              <Card.Img variant="top" src={member.value.thumbnailUrl} />
              <Card.Body>
                <Card.Title>{member.value.nameDisplayAs}</Card.Title>
                <Card.Text>
                  <strong>Party:</strong> {member.value.latestParty.name} <br />
                  <strong>House:</strong> {member.value.latestHouseMembership.house === 1 ? 'Commons' : 'Lords'} <br />
                  <strong>Constituency:</strong> {member.value.latestHouseMembership.membershipFrom} <br />
                </Card.Text>
                <Link to={`/members/${member.value.id}`}>View Profile</Link>
              </Card.Body>
            </Card>
          </div>
        ))}
      </div>
      <div className="d-flex justify-content-center">
        <Button onClick={loadMoreMembers} disabled={isLoadingMore}>
          {isLoadingMore ? 'Loading...' : 'Load More'}
        </Button>
      </div>
    </div>
  );
};

export default Members;
