// src/pages/Government.js
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { GovernmentContext } from '../context/GovernmentContext';
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';
import 'bootstrap/dist/css/bootstrap.min.css';

const Government = () => {
  const { governmentPosts, loading } = useContext(GovernmentContext);

  if (loading) {
    return <Spinner animation="border" />;
  }

  return (
    <div className="container">
      <h1>Government Members</h1>
      <div className="row">
        {governmentPosts.map(post => (
          <div className="col-md-4" key={post.value.id}>
            <Card className="mb-3">
              <Card.Body>
                <Card.Title>{post.value.name}</Card.Title>
                {post.value.postHolders.map(holder => (
                  <div key={holder.member.value.id}>
                    <Link to={`/member/${holder.member.value.id}`}>
                      <Card.Img variant="top" src={holder.member.value.thumbnailUrl} />
                    </Link>
                    <Card.Text>
                      <Link to={`/member/${holder.member.value.id}`}>
                        <strong>Name:</strong> {holder.member.value.nameDisplayAs} <br />
                      </Link>
                      <strong>Party:</strong> {holder.member.value.latestParty.name} <br />
                      <strong>House:</strong> {holder.member.value.latestHouseMembership.house === 1 ? 'Commons' : 'Lords'} <br />
                      <strong>Start Date:</strong> {new Date(holder.startDate).toLocaleDateString()}
                    </Card.Text>
                  </div>
                ))}
              </Card.Body>
            </Card>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Government;
