// src/context/BillsContext.js
import React, { createContext, useState, useEffect, useCallback } from 'react';
import axios from 'axios';

export const BillsContext = createContext();

export const BillsProvider = ({ children }) => {
  const [bills, setBills] = useState({ All: [] });
  const [categories, setCategories] = useState(['All']);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  const fetchAllCategories = useCallback(async () => {
    try {
      const response = await axios.get('https://api.whatsthe411.co.uk/api/bills', {
        params: { skip: 0, take: 1000 } // Adjust the number to ensure you get all categories
      });
      const billsData = response.data.items;

      const newCategories = new Set(['All']);
      const newBills = { All: [] };

      billsData.forEach(bill => {
        const billYear = new Date(bill.lastUpdate).getFullYear();
        if (billYear === 2024) {
          newBills.All.push(bill);
          newCategories.add(bill.originatingHouse);
          if (newBills[bill.originatingHouse]) {
            newBills[bill.originatingHouse].push(bill);
          } else {
            newBills[bill.originatingHouse] = [bill];
          }
        }
      });

      setCategories([...newCategories]);
      setBills(newBills);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching categories and bills', error);
      setLoading(false);
    }
  }, []);

  const fetchBills = useCallback(async () => {
    try {
      const response = await axios.get('https://api.whatsthe411.co.uk/api/bills', {
        params: { skip: page * 20, take: 20 }
      });
      const billsData = response.data.items;

      const newBills = { ...bills, All: [...bills.All] };

      billsData.forEach(bill => {
        const billYear = new Date(bill.lastUpdate).getFullYear();
        if (billYear === 2024) {
          newBills.All.push(bill);
          if (newBills[bill.originatingHouse]) {
            newBills[bill.originatingHouse].push(bill);
          } else {
            newBills[bill.originatingHouse] = [bill];
          }
        }
      });

      setBills(newBills);
      setHasMore(billsData.length > 0);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching bills', error);
      setLoading(false);
    }
  }, [page, bills]);

  useEffect(() => {
    fetchAllCategories();
  }, [fetchAllCategories]);

  useEffect(() => {
    if (page > 0) {
      fetchBills();
    }
  }, [fetchBills, page]);

  return (
    <BillsContext.Provider value={{ bills, loading, hasMore, setPage, categories }}>
      {children}
    </BillsContext.Provider>
  );
};
